@charset "UTF-8";

/* src/app/report-shipments-recent-cutom-paginator/report-shipments-recent-cutom-paginator.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
#outer {
  width: 100%;
  text-align: center;
  height: 80px;
  padding: 15px 25px 0px 0px;
  color: var(--ups-brand-colors-ups-blue, #0662BB);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.inner {
  display: inline-block;
  padding-inline-start: 8px;
}
.inner a {
  text-decoration: none;
}
.inner mat-select {
  width: 140px;
}
.inner span {
  content: "\203a";
}
.inner .arrow {
  font-size: x-large;
}
.inner .disabled-link {
  pointer-events: none;
  color: var(--ups-brand-colors-ups-grey-3, #BFB8AF);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.inner .prev-and-next-btn {
  color: #004791;
}
/*# sourceMappingURL=report-shipments-recent-cutom-paginator.component.css.map */
