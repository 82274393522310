/* src/app/report-shipments-bulkupload/report-shipments-bulkupload.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.header {
  color: #121212;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  display: flex;
}
@media screen and (min-width: 48rem) {
  .header {
    font-size: 20px;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .header {
    margin-top: 18px;
  }
}
.page-description {
  padding-right: 25px;
  color: #121212;
  font-weight: 400;
  font-size: 11.93px;
  line-height: 20.51px;
  text-align: left;
}
.form-wrapper {
  width: 100%;
  gap: 30px;
  opacity: 0px;
}
.form-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.form-group h4 {
  color: #121212;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
}
.form-group .download-template {
  font-size: 14px;
}
.upload-area {
  width: auto;
  padding: 20px;
  justify-content: center;
}
.upload-area .upload-box {
  width: 100%;
  height: 166.33px;
  border: 2.49px solid rgba(78, 83, 87, 0.6);
  border-radius: 5.97px 0px 0px 0px;
  border-image:
    repeating-linear-gradient(
      135deg,
      #ccc 2px,
      #ccc 10px,
      transparent 10px,
      transparent 20px) 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
}
.upload-area .upload-box:hover {
  background-color: #f0f0f0;
}
.upload-area .upload-box .excel-4954 {
  width: 14.918px;
  height: 14.918px;
  margin-left: 5px;
}
.upload-area .upload-box .file-input {
  display: none;
}
.upload-area .upload-box .drive-folder-icon {
  margin-top: 20px;
}
.upload-area .upload-box .unselected-file-upload-text {
  font-size: 11.93px;
  font-weight: 400;
  line-height: 17.9px;
}
.upload-area .upload-box .unselected-file-upload-text .file-size {
  font-size: 11.93px;
  font-weight: 700;
  line-height: 13.99px;
}
.upload-area .upload-box .unselected-file-upload-text .file-types {
  font-size: 11.93px;
  font-weight: 400;
  line-height: 17.9px;
}
.upload-area .upload-box .unselected-file-upload-text .click-to-upload {
  color: #004791;
}
.upload-area .upload-box .btn-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.upload-area .upload-box .btn-container .mdc-button {
  font-size: 0.75rem;
}
.upload-area .upload-box .error-file-upload {
  display: flex;
  color: var(--UPS-Brand-Colors-UPS-Error-Red, #DF2901);
  font-size: 11.93px;
  font-weight: 400;
  line-height: 17.9px;
  margin-bottom: 19px;
}
.upload-area .upload-box .error-file-upload .error-icon {
  display: flex;
  width: 11.934px;
  height: 11.934px;
}
.upload-area .upload-box .error-file-upload .error-message {
  display: flex;
  color: var(--UPS-Brand-Colors-UPS-Error-Red, #DF2901);
  font-size: 8.951px;
  font-weight: 500;
  padding-left: 2.98px;
}
.upload-area .upload-box .error-file-upload .duplicate-addresses {
  color: var(--Capital---Gray-2, #4E5357);
  margin: 5.97px 0 10px 0;
  font-size: 11.934px;
  font-weight: 400;
  line-height: 11.934px;
  letter-spacing: 0.06px;
}
.selected-file-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-file-container .file-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected-file-container .file-wrapper .excel-4954 {
  width: 14.918px;
  height: 14.918px;
}
.selected-file-container p {
  color: var(--Capital---Gray-2, #4E5357);
  font-size: 11.934px;
  font-weight: 400;
  line-height: 11.934px;
  letter-spacing: 0.06px;
  margin-left: 7.46px;
}
.selected-file-container span {
  color: #0A8080;
}
.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}
.instructions-container {
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
}
.download-instructions {
  border: 1px solid #DFDBD7 !important;
  width: 360px;
  height: auto;
  color: #5F5753 !important;
}
.download-instructions ::ng-deep .mat-mdc-raised-button {
  color: #5F5753 !important;
  font-size: 14px !important;
  width: 100%;
}
.info-section {
  margin: 15px 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.info-section h3 {
  margin: 0;
  font-size: 10.76px;
  line-height: 18.45px;
  align-items: center;
  font-weight: 600;
}
.info-section p {
  font-size: 10px;
  line-height: 11.72px;
  font-weight: 400;
}
.info-section ul {
  list-style: none;
  padding: 0;
}
.info-section li {
  font-size: 10px;
  line-height: 11.72px;
  font-weight: 400;
}
.mat-divider.mat-divider-vertical {
  margin: 0 20px;
  background-color: rgba(128, 128, 128, 0.5);
  height: 70px;
  position: sticky;
  top: 14%;
}
::ng-deep .mat-expansion-panel-body {
  display: flex !important;
  max-width: 1055px !important;
}
.info-section mat-icon {
  margin-right: 10px;
  color: #5c6bc0;
  width: fit-content;
  height: fit-content;
  align-self: center;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #5c6bc0;
}
.alternative-button {
  box-shadow: none !important;
  border: solid thin #004791 !important;
  color: #004791 !important;
  background-color: #F5F9FE !important;
}
.upload-box-hover {
  background-color: #f0f0f0;
}
.instructions--content {
  padding: 1em 0;
}
/*# sourceMappingURL=report-shipments-bulkupload.component.css.map */
