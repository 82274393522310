/* src/app/history/billing-history/charge-credit-card/charge-credit-card.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.charge-credit-card .ccv {
  width: 5em;
}
.charge-credit-card .note {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2em;
}
.charge-credit-card .note .mat-icon {
  margin-right: 0.25em;
}
.charge-credit-card .content-items {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
@media screen and (min-width: 48rem) {
  .charge-credit-card .content-items {
    width: 25em;
  }
}
.charge-credit-card .content-items .content-items-title {
  font-family: "Roboto Flex", sans-serif;
  font-weight: 700;
}
.charge-credit-card .content-items .content-item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding: 0.5em 0;
  white-space: pre;
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .charge-credit-card .content-items .content-item {
    justify-content: flex-start;
  }
}
.charge-credit-card .content-items .content-item .key,
.charge-credit-card .content-items .content-item .value {
  width: 49%;
}
@media screen and (min-width: 48rem) {
  .charge-credit-card .content-items .content-item .key,
  .charge-credit-card .content-items .content-item .value {
    width: 12em;
  }
}
.charge-credit-card .content-items .content-item .value {
  font-weight: 700;
  white-space: initial;
  text-align: right;
}
@media screen and (min-width: 48rem) {
  .charge-credit-card .content-items .content-item .value {
    text-align: left;
  }
}
.charge-credit-card .mat-mdc-form-field {
  margin-left: 0;
}
/*# sourceMappingURL=charge-credit-card.component.css.map */
