/* src/app/report-shipments-bulkupload/tips-tricks/tips-tricks.component.scss */
ul.items {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
@media screen and (min-width: 48rem) {
  ul.items {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
  }
}
ul.items li.item {
  padding: 1em;
  display: grid;
  grid-template-areas: "icon title" "icon description";
  gap: 0.25em 1em;
}
ul.items li.item .icon {
  grid-area: icon;
}
ul.items li.item .title {
  grid-area: title;
}
ul.items li.item .description {
  grid-area: description;
}
@media screen and (min-width: 48rem) {
  ul.items li.item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.25em;
    padding: 1em 2em;
  }
}
ul.items li.item:not(:last-child) {
  border-bottom: solid 3px #DFDBD7;
}
@media screen and (min-width: 48rem) {
  ul.items li.item:not(:last-child) {
    border-bottom: none;
    position: relative;
  }
  ul.items li.item:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0.14413em;
    height: 65%;
    background-color: #DFDBD7;
  }
}
ul.items li.item .icon {
  align-self: center;
  justify-self: center;
}
ul.items li.item .title {
  font-size: 0.672565em;
  font-weight: 700;
  color: black;
}
ul.items li.item .description {
  font-size: 0.672565em;
  font-weight: 400;
  color: #5F5753;
  text-align: justify;
  line-height: 1.25;
  margin: 0;
}
ul.items li.item ol.columns {
  column-count: 2;
  column-gap: 2em;
  padding: 0 0 0 1em;
  margin: 0;
}
:host(.bulk-upload-tips-tricks) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  font-size: 1rem;
}
/*# sourceMappingURL=tips-tricks.component.css.map */
