/* src/app/report-shipments-single-upload/report-shipments-single-upload.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.report-shipments-single-upload {
  --mat-form-field-subscript-text-size: 12px;
  background-color: white;
  font-size: 1rem;
  height: 100%;
}
.report-shipments-edit-upload {
  --mat-form-field-subscript-text-size: 12px;
  background-color: white;
  font-size: 1rem;
  height: 607px;
}
.section-title {
  color: #004791;
  font-size: 1.35em;
  font-weight: 700;
  margin-bottom: 1em;
}
.form-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  height: auto;
}
.edit-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  height: auto;
}
.form-container {
  flex: 1;
  height: auto;
  background: #fff;
  padding: 20px 0;
  border-radius: 8px;
}
@media screen and (min-width: 48rem) {
  .form-container {
    max-width: 48%;
  }
}
.general-info {
  width: 100%;
  height: auto;
}
.shipping-info {
  width: 100%;
  height: auto;
}
.edit-sections {
  width: 250px;
}
.ship-to-address .ship-from-address {
  height: 100%;
}
.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.invalid-dhl-country {
  margin-top: 25px;
}
.edit-form-group {
  display: flex;
  flex-direction: column;
  width: 250px;
}
.inner-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .inner-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    gap: 10px;
  }
}
.checkbox {
  left: 0px !important;
  position: relative !important;
}
.form-checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
}
.question-container {
  flex-direction: row;
  width: 880px;
  margin-top: 40px;
  padding-left: 20px;
}
.question-container h4 {
  font-weight: bold;
}
.question-container ::ng-deep .mdc-button__label {
  font-size: 14px;
}
.question-container hr {
  border: 1px solid #DFDBD7;
  margin-top: 20px;
}
.question-container span {
  font-size: 14px;
}
.question-container span a {
  color: #0662BB;
}
::ng-deep .mat-mdc-raised-button:not([class*=mat-elevation-z]):not([disabled]):not(.disabled).alternative-button {
  border-color: #DFDBD7;
  color: #5F5753;
}
::ng-deep .mat-mdc-raised-button:not([class*=mat-elevation-z]):not([disabled]):not(.disabled).alternative-button:hover {
  border-color: #DFDBD7;
  color: #5F5753;
  background-color: initial;
}
::ng-deep .mat-mdc-form-field-error-wrapper {
  padding: 0px;
}
.download-instructions {
  color: #5F5753 !important;
  margin-bottom: 1em;
}
.long-error {
  font-size: 10px;
}
/*# sourceMappingURL=report-shipments-single-upload.component.css.map */
